import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./App.css";
import * as Y from "yjs";
import { WebsocketProvider } from "./utils/y-websocket";
import { proxy, useSnapshot } from "valtio";
import { bindProxyAndYMap } from "valtio-yjs";
import Draggable from "react-draggable";
import throttle from "lodash/debounce";

const ydoc = new Y.Doc();
const username = Date.now().toString();
const wsURL = "wss://orbit-durable-worker.josh-mcfarlin.workers.dev/ws";
const project = "abc";

const websocketProvider = new WebsocketProvider(wsURL, project, ydoc, {
  connect: true,
  params: {
    u: username,
  },
});

websocketProvider.on("status", (event) => {
  console.log(event.status); // logs "connected" or "disconnected"
});

const yMap = ydoc.getMap("pos");
const state = proxy({
  x: 25,
  y: 25,
});
bindProxyAndYMap(state, yMap);

const dragHandler = (e, newPos) => {
  //console.log(newPos.x, newPos.y);
  state.x = newPos.x;
  state.y = newPos.y;
};

const App = () => {
  const nodeRef = React.useRef(null);
  const { x, y } = useSnapshot(state);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const limDrag = React.useCallback(throttle(dragHandler, 10), []);

  return (
    <div className="container">
      <Draggable
        axis="both"
        allowAnyClick
        bounds="parent"
        position={{ x, y }}
        grid={[1, 1]}
        scale={1}
        onDrag={limDrag}
        nodeRef={nodeRef}
      >
        <div className="box" ref={nodeRef} />
      </Draggable>
    </div>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
